export const news = [
    {
        name: 'PR Newswire',
        text: 'Mitchell Teams with Inspektlabs to Offer New Option for AI-Generated Vehicle Appraisals',
        logo: 'prnewswire.png',
        url: 'https://www.prnewswire.com/news-releases/mitchell-teams-with-inspektlabs-to-offer-new-option-for-ai-generated--vehicle-appraisals-301362018.html'
    },
    {
        name: 'Insurtech Insights',
        text: 'Liberty General Insurance has automated vehicle inspections for car insurance policy renewals and claim assessments using Inspektlabs\' AI based technology. This is expected to transform the car inspection process, which is currently dominated by manual inspections - a people intensive job leading to delays in the claims and policy issuance process.',
        logo: 'insurtech.png',
        url: 'https://www.insurtechinsights.com/liberty-general-insurance-rolls-out-ai-based-automated-car-inspections/'
    },
    {
        name: 'Mitchell',
        text: 'Mitchell Teams with Inspektlabs to Offer New Option for AI-Generated Vehicle Appraisals',
        logo: 'mitchell.png',
        url: 'https://www.mitchell.com/mitchellnews/Detail/ArticleID/3822/Mitchell-Teams-with-Inspektlabs-to-Offer-New-Option-for-AI-Generated-Vehicle-Appraisals'
    },
    {
        name: 'aftermarketNews',
        text: 'Insurers can now use Mitchell Intelligent Estimating with AI from Inspektlabs to automate and expedite the auto claims handling',
        logo: 'aftermarketnews.png',
        url: 'https://www.aftermarketnews.com/mitchell-inspektlabs-offer-new-option-for-ai-generated-vehicle-appraisals/'
    },
    {
        name: 'Insurtech Insights',
        text: 'Founded in 2019, Inspektlabs is a platform leveraging patent-pending computer vision technology to automate inspections of any physical assets – initially for the auto and insurance industries.',
        logo: 'insurtech.png',
        url: 'https://www.insurtechinsights.com/wp-content/uploads/2021/07/Future50-Americas-2021.pdf'
    },
    {
        name: 'GT Motive',
        text: 'Inspektlabs joins GT Fusion, the open AI ecosystem from GT Motive that combines AI with automotive repair expertise.',
        logo: 'gt-motive.png',
        url: 'https://gtmotive.com/en/inspektlabs-joins-gt-fusion-ecosystem/'
    },
    {
        name: 'NTT Data',
        text: 'The Grand Champion Inspektlabs\' proposal for NTT DATA was to create a business using their technology that can identify parts of a vehicle or damage on a vehicle from a smartphone photograph or video and create an inspection report within seconds. The technology is excellent in terms of its capability and high accuracy in damage detection. Since the technology allows for the identification of damage on other types of objects as well, it is expected to be applicable for insurance, manufacturing, and other industries.',
        logo: 'nttdata.png',
        url: 'https://in.nttdata.com/en/news/press-release/2021/february/result-of-open-innovation-contest-11'
    },
    {
        name: 'VC Circle',
        text: 'Better Capital, Snapdeal founders invest in AI startup Inspektlabs',
        logo: 'vccircle.png',
        url: 'https://www.vccircle.com/better-capital-snapdeal-founders-invest-in-ai-startup-inspektlabs/'
    },
    {
        name: 'Barclays',
        text: 'Inspektlabs uses Inspection-as-a-Service software to automate photo or video-based inspection of any asset using artiﬁcial intelligence.',
        logo: 'barclays.png',
        url: 'https://home.barclays/who-we-are/innovation/barclays-accelerator/companies/inspektlabs/'
    },
    {
        name: 'Techstars',
        text: '10 Hottest Fintech Companies Head To Rise London',
        logo: 'techstars.png',
        url: 'https://www.techstars.com/newsroom/10-hottest-fintech-companies-head-to-rise-london'
    },
    {
        name: 'PR Times',
        text: '「ニューノーマル時代のスマートシティ」を世界中のスタートアップと共創するグローバル・オープンイノベーション・プログラム「SmartCityX」新たな企業や自治体が参画し、スタートアップ18社を早期採択',
        logo: 'prtimes.png',
        url: 'https://prtimes.jp/main/html/rd/p/000000026.000015315.html'
    },
    {
        name: 'Yourstory',
        text: 'Inspektlabs is a computer vision technology platform focused on automation of inspections of any physical asset using photos and videos. Founded by Devesh Trivedi, its current focus is on automating car inspections for automotive and insurance players.',
        logo: 'yourstory.svg',
        url: 'https://yourstory.com/2020/12/meet-19-innovative-startups-graduating-chiratae-ventures-innovators-program/'
    },
    {
        name: 'The Print',
        text: 'TiE Pune to help local start-ups go global – Ties up with NTT DATA to select start-ups with potential',
        logo: 'theprint.png',
        url: 'https://theprint.in/ani-press-releases/tie-pune-to-help-local-start-ups-go-global-ties-up-with-ntt-data-to-select-start-ups-with-potential/693517/'
    },
    {
        name: 'Hindustan Times',
        text: 'Inspektlabs reduces the cost of inspection and time of insurance and automotive companies by almost 95 per cent, with their differentiating factor being the ability to detect not only the object, but also the state of the object in question',
        logo: 'hindustantimes.svg',
        url: 'https://www.hindustantimes.com/cities/others/startup-mantra-damage-claims-now-get-ai-video-check-101615638946378.html'
    },
    {
        name: 'Plug And Play',
        text: 'Summer Startups 2021 Presenting the 154 batch startups from 9 of our industry-focused innovation programs: Energy, Enterprise Tech, Fintech, Health, Insurtech, Internet of Things, Mobility, Real Estate & Construction, and Travel & Hospitality.',
        logo: 'plug-and-play.png',
        url: 'https://www.plugandplaytechcenter.com/summer-2021/'
    },
]